
@import "../../bootstrap/variables.less";

@body-bg:					#CF854A;

@text-color:				#fff;
@link-color:				#431E01;
@link-hover-color:			#7B4215;
@link-hover-decoration:		underline;

@font-family-base:			Arial, Helvetica, sans-serif;
@font-size-base:			10px;
@line-height-base:			1;

@font-size-h1:				30px;
@headings-small-color:		#fff;

@thumb-width:				490px;
@thumb-height:				368px;
@thumb-margin-top:			1px;
@thumb-margin-right:		1px;
@thumb-margin-bottom:		1px;
@thumb-margin-left:			1px;
@thumb-border-width:		1px;
@thumb-border-width:		1px;
@thumb-border:				1px solid #000;
@thumb-hover-border:		1px dashed #fff;

@pager-border:					none;
@pager-padding:					2px;
@pager-text-decoration:			underline;
@pager-color:					#431E01;
@pager-color-active:			#7B4215;
@pager-text-decoration-active:	none;
@pager-hover-bg:				transparent;
@pager-font-weight-active:		normal;
@pager-font-size:				24px;
@pager-font-size-mobile:		24px;

@zindex-popup:				100;
@tooltip-arrow-width:		5px;
@tooltip-bg:				#444;
@popup-text-color:			#fff;
@popup-font-weight:			bold;

@social-links-margin:		10px 0;

@sites-list-font-size:				20px;
@sites-list-color:					#fff;
@sites-list-title-margin-bottom:	5px;
@sites-list-title-size:				20px;
@sites-list-title-weight:			normal;
@sites-list-title-decoration:		none;

@import "common/bootstrap.less";
@import "../../common/utils.less";
@import "../../common/flags-small.less";
@import "../../common/flags.less";
@import "common/modules.less";
@import "common/sites_list.less";
@import "common/popup.less";
@import "common/mozaique_simple.less";
@import "common/pagination.less";

a {
	text-decoration: underline;
	cursor: pointer;
}
h1 {
	text-align: center;
	small {
		font-size: 20;
	}
}
#language-switcher {
	position: absolute;
	top: 0;
	right: 10px;
}
.pagination ul {
	text-align: center;
}
.trades {
	margin: 20px auto 3px;
	font-size: 14px;
	font-weight: bold;
}